import { Typography } from "@/components/ui/components/typography";
import { Money, CurrencyHelper } from "@/lib/currency";
import { cn } from "@/lib/utils";
import React from "react";

export type ProductPriceValueProps = {
	price?: Money;
	locale?: string;
	className?: string;
};

export const ProductPriceValue = ({
	price,
	locale,
	className,
}: ProductPriceValueProps) =>
	price ? (
		<Typography.Heading className={cn("asio-product-price__value", className)}>
			{CurrencyHelper.formatMoneyCurrency(price, locale)}
		</Typography.Heading>
	) : null;
