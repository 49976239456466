import * as React from "react";
import { cn } from "@/lib/utils";
import { Image } from "./image";
import { CurrencyHelper } from "@/lib/currency";
import { Money } from "@/shared/types/product";

const Card = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div ref={ref} className={cn("asio-item-card", className)} {...props} />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("asio-item-card__header", className)}
		{...props}
	/>
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("asio-item-card__content-title", className)}
		{...props}
	/>
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
	<p
		ref={ref}
		className={cn("asio-item-card__content-description", className)}
		{...props}
	/>
));
CardDescription.displayName = "CardDescription";

const CardPrice = React.forwardRef<
	HTMLParagraphElement,
	{
		className?: string;
		price?: Money;
		hasStock?: boolean;
	} & React.HTMLAttributes<HTMLParagraphElement>
>(({ className, price, hasStock, ...props }, ref) => (
	<p
		ref={ref}
		className={cn("asio-item-card__content-price", className)}
		{...props}
	>
		{price && hasStock ? CurrencyHelper.formatMoneyCurrency(price) : "Out Of Stock"}
	</p>
));

CardPrice.displayName = "CardPrice";

const CardContent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("asio-item-card__content", className)}
		{...props}
	/>
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn("asio-item-card__footer", className)}
		{...props}
	/>
));
CardFooter.displayName = "CardFooter";

export {
	Card,
	CardHeader,
	CardFooter,
	CardTitle,
	CardPrice,
	CardDescription,
	CardContent,
};
