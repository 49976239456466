import { Currency } from "@commercetools/frontend-sdk/lib/types/Currency";

type LocalizationMapping = {
	locale: string;
	currency: Currency;
	currencyCode: string;
	countryName: string;
	countryCode: string;
	countryKey: string;
};

const localizationMapper = {
	en: {
		locale: "en_US",
		currency: "USD",
		currencyCode: "$",
		countryCode: "US",
		countryName: "United States",
		countryKey: "us",
	},
} as Record<string, LocalizationMapping>;

export function getLocalizationInfo(locale: string) {
	if (!(locale in localizationMapper)) {
		console.warn(
			`Invalid locale ${locale} provided. Possible values are ${Object.keys(
				localizationMapper
			).join(", ")}`
		);

		return localizationMapper.en;
	}

	return localizationMapper[locale as keyof typeof localizationMapper];
}
