import { Price } from "@/components/product/detail/price";
import { ProductTile } from "@/components/product/tile";
import { Button } from "@/components/ui/components/button";
import { Image } from "@/components/ui/components/image";
import { Typography } from "@/components/ui/components/typography";
import { Trash2Icon } from "lucide-react";

export type CartItem = {
	id: string;
	name: string;
	price: string;
	discountedPrice: string;
	amount: number;
	attributes: Record<string, string>;
	img: {
		src?: string;
		alt: string;
	};
	isOnStock?: boolean;
};

export const CartProductTile = ({
	item,
	onRemoveItem,
	isPending,
}: {
	item: CartItem;
	isPending?: boolean;
	onRemoveItem?: (item: CartItem) => void;
}) => {
	return (
		<ProductTile className={isPending ? 'asio-product-tile__disabled' : ''}>
			<ProductTile.Panel className="asio-product-tile__img-panel">
				<Image
					src={item?.img?.src?.replace('.jpg', '-small.jpg') ??"/images/image-placeholder-small.png"}
					alt={item.name}
					className={
						item.isOnStock
							? "asio-product-tile__img"
							: "asio-product-tile__img grayscale"
					}
				/>
			</ProductTile.Panel>

			<ProductTile.Panel
				className={
					item.isOnStock
						? "asio-product-tile__details-panel"
						: "asio-product-tile__details-panel !text-gray-500"
				}
			>
				<ProductTile.Title
					value={item.name ??""}
					className="text-sm font-semibold"
				/>
				<div className="flex flex-col flex-end mt-2">
					<Typography.Text className="text-xs">
						<Typography.Text as="span" weight="bold" className="mr-1">
							Quantity:
						</Typography.Text>
						{item.amount}
					</Typography.Text>
					{Object.keys(item.attributes).map((attribute, index) => (
						<Typography.Text
							key={`${attribute}-${index}`}
							className="text-xs capitalize"
						>
							<Typography.Text as="span" weight="bold" className="mr-1">
								{attribute}:
							</Typography.Text>
							{item.attributes[attribute]}
						</Typography.Text>
					))}
				</div>
			</ProductTile.Panel>

			<ProductTile.Panel className="asio-product-tile__actions-panel">
				<Price className="asio-product-tile__price-container">
					<Typography.Heading
						className={
							item.isOnStock
								? "asio-product-price-discount__container"
								: "asio-product-price-discount__container !text-gray-500"
						}
					>
						{item.discountedPrice !== "$0" ? item.discountedPrice : item.price}
					</Typography.Heading>
					{item.discountedPrice !== "$0" && (<Typography.Heading className="asio-product-price__value">
						{item.price}
					</Typography.Heading>)}
				</Price>

				{onRemoveItem && (
					<Button
						variant="ghost"
						onClick={() => onRemoveItem(item)}
					>
						<Trash2Icon className="h-4 w-4" />
					</Button>
				)}
				{!item.isOnStock && <p className="asio-product__tag">Out of stock</p>}
			</ProductTile.Panel>
		</ProductTile>
	);
};
