'use client';

import React from 'react';
import NextImage from 'next/image';
import cloudinaryLoader from './loaders/cloudinary';
import defaultLoader from './loaders/default';
import { ImageProps } from './types';
import useParameterizedSrc from './hooks/use-parameterized-src';
import useDimensions from './hooks/use-dimensions';

export const Image = ({
	media,
	ratio,
	gravity,
	suffix,
	src,
	width,
	height,
	alt = '',
	title,
	...props
}: ImageProps) => {
	const parameterizedSrc = useParameterizedSrc({
		ratio,
		gravity,
		suffix,
		media,
		src,
	});

	const dimensions = useDimensions({ media, width, height, ...props });

	if (!media?.mediaId)
		return (
			<NextImage
				src={parameterizedSrc}
				loader={defaultLoader}
				alt={alt}
				title={title}
				{...dimensions}
				{...props}
			/>
		);

	return (
		<NextImage
			src={parameterizedSrc}
			loader={cloudinaryLoader}
			alt={alt}
			title={title}
			{...dimensions}
			{...props}
		/>
	);
};

export * from './types';
