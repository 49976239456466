"use client";

import { sdk } from "@/commercetools/sdk";
import { CartItem } from "@/components/cart/templates/cart-product-tile";
import { CartSearchParams } from "@/components/product/types";
import { useDisclosure } from "@/hooks/use-disclosure";
import { useUrlParams } from "@/hooks/use-url-params";
import { useSearchParams } from "next/navigation";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import type { Dispatch, PropsWithChildren, SetStateAction } from "react";

type CartDrawerContextState = PropsWithChildren<{
	isOpen?: boolean;
	items: CartItem[] | undefined;
	isAddingNewItem: boolean;
}>;

type Handlers = {
	onToggle: () => void;
	setIsAddingNewItem: Dispatch<SetStateAction<boolean>>;
};

const CartDrawerContext = createContext<
	[CartDrawerContextState, Handlers] | undefined
>(undefined);

export function CartDrawerProvider({
	children,
	items,
}: Partial<CartDrawerContextState>) {
	const { setParams } = useUrlParams();
	const searchParams = useSearchParams();
	const { isOpen: isOpenCartDrawer, onOpen, onClose } = useDisclosure();
	const [isAddingNewItem, setIsAddingNewItem] = useState(false);

	useEffect(() => {
		setIsAddingNewItem(false);
	}, [items]);

	/** MONKEY PATCH (Author Leo): the SDK to use CoFe needs to interact with frontastic-session cookie directly from browser.
	 * We need to be sure call just one time getCart() method to avoid multiple calls to the same endpoint.
	 */
	useEffect(() => {
		(async () => {
			sdk.configureForNext("en");
			await sdk.composableCommerce.cart.getCart();
		})();
	}, []);

	const handleToggle = useCallback(() => {
		if (
			isOpenCartDrawer ||
			searchParams.get(CartSearchParams.CartOpened) === "true"
		) {
			onClose();
			setParams(CartSearchParams.CartOpened, "false");
		} else {
			onOpen();
			setParams(CartSearchParams.CartOpened, "true");
		}
	}, [onOpen, onClose, setParams, isOpenCartDrawer, searchParams]);

	const handlers = useMemo(
		() => ({
			onToggle: handleToggle,
			setIsAddingNewItem,
		}),
		[handleToggle]
	);

	return (
		<CartDrawerContext.Provider
			value={[
				{
					isOpen:
						isOpenCartDrawer ||
						searchParams.get(CartSearchParams.CartOpened) === "true",
					items,
					isAddingNewItem,
				},
				handlers,
			]}
		>
			{children}
		</CartDrawerContext.Provider>
	);
}

export const useCartDrawerContext = () => {
	const context = useContext(CartDrawerContext);

	if (context === undefined) {
		throw new Error(
			`useCartDrawerContext must be used within a CartDrawerProvider`
		);
	}

	return context;
};
