import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';

export const ProductTilePanel = ({
	className,
	children,
}: PropsWithChildren & { className?: string }) => {
	return (
		<div className={cn('asio-product-tile__container', className)}>
			{children}
		</div>
	);
};
