import { cn } from "@/lib/utils";
import React, { HTMLAttributes } from "react";

type DividerProps = HTMLAttributes<HTMLDivElement>;

export const Divider = ({ className }: DividerProps) => (
	<div className={cn("asio-divider", className, )}>
		<div />
	</div>
);

Divider.displayName = "Divider";
