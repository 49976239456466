import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { ZodError } from "zod";
import { z } from "zod";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const nonEmptyString = z
	.string()
	.transform((t) => t?.trim())
	.pipe(z.string().refine((s) => s.length > 0, "This field is required"));

const emptyStringToUndefined = z.literal("").transform(() => undefined);

export function asOptionalField<
	T extends z.ZodString | z.ZodPipeline<z.ZodString, z.ZodString>
>(schema: T) {
	return schema.nullish().or(emptyStringToUndefined);
}

export const parseZodError = (error: any) => {
	return {
		message:
			String(error).split("Error: ").length > 1
				? String(error).split("Error: ").pop()
				: "An error ocurred",
		errors: (error as ZodError).errors ?? error,
	};
};

export const getPriceQueryParams = (
	queryParams: URLSearchParams,
	key: string,
	value: string | null
) => {
	const hasValueInParam = queryParams.has(key);

	if (value && hasValueInParam) {
		queryParams.set(key, value);
	} else if (value) {
		queryParams.append(key, value);
	} else if (hasValueInParam) {
		queryParams.delete(key);
	}
	return queryParams;
};

export const capitalizeFirstLetter = (value: string) => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};
export const formatText = (text?: string) =>
	text?.toLowerCase().replace(/^\w/, (l) => l.toUpperCase());

export const formatDate = (date: Date) => {
	const options: Intl.DateTimeFormatOptions = { weekday: "short", month: "short", day: "2-digit", year: "numeric"};
	return new Date(date).toLocaleDateString("en-US", options);
};