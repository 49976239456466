export const ProductAttribute = {
	Size: "size",
	Color: "color",
} as const;

export type ProductAttribute =
	(typeof ProductAttribute)[keyof typeof ProductAttribute];

export const CartSearchParams = {
	ItemsToAdd: "items-to-add",
	CartOpened: "cart-opened",
} as const;

export type CartSearchParams =
	(typeof CartSearchParams)[keyof typeof CartSearchParams];

export type SearchParams = { [key: string]: string };
