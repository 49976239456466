import { Typography } from "@/components/ui/components/typography";
import { Money, CurrencyHelper } from "@/lib/currency";
import { cn } from "@/lib/utils";
import React from "react";

export type ProductPriceDiscountProps = {
	locale?: string;
	className?: string;
	discountedPrice?: Money;
};

export const ProductPriceDiscount = ({
	locale,
	className,
	discountedPrice,
}: ProductPriceDiscountProps) =>
	discountedPrice ? (
		<Typography.Heading
			className={cn("asio-product-price-discount__container", className)}
		>
			{CurrencyHelper.formatMoneyCurrency(discountedPrice, locale)}
		</Typography.Heading>
	) : null;
