export type Money = {
	fractionDigits?: number;
	centAmount?: number;
	currencyCode?: string; // The currency code compliant to ISO 4217.
};

type Locale = string; // Assuming Locale is a string

export class CurrencyHelper {
	static formatStringToCurrency(
		value: string | number,
		locale: Locale = "en-US",
		currencyCode: string = "USD"
	): string {
		const numericValue = typeof value === "string" ? parseFloat(value) : value;

		if (isNaN(numericValue)) {
			throw new Error(`Invalid numeric value: ${value}`);
		}

		return new Intl.NumberFormat(locale, {
			style: "currency",
			currency: currencyCode,
		}).format(numericValue);
	}

	static formatMoneyCurrency(price: Money, locale: Locale = "en-US"): string {
		return new Intl.NumberFormat(locale, {
			style: "currency",
			currency: price.currencyCode || "USD",
			maximumFractionDigits: price.fractionDigits || 0,
			minimumFractionDigits: price.fractionDigits || 0,
		}).format((price.centAmount || 0) / 100);
	}

	static addCurrency(value1?: Money, value2?: Money): Money {
		if (!value1) value1 = { centAmount: 0 };
		if (!value2) value2 = { centAmount: 0 };

		if (
			value1.fractionDigits !== value2.fractionDigits &&
			value1.fractionDigits &&
			value2.fractionDigits
		) {
			throw new Error(
				`Money with different fraction codes passed to addCurrency, value returned will be inaccurate. ` +
					`Value 1: ${value1.fractionDigits}, value 2: ${value2.fractionDigits}`
			);
		}

		if (
			value1.currencyCode !== value2.currencyCode &&
			value1.currencyCode &&
			value2.currencyCode
		) {
			throw new Error(
				`Money with different currency codes passed to addCurrency, value returned will be inaccurate. ` +
					`Value 1: ${value1.currencyCode}, value 2: ${value2.currencyCode}`
			);
		}

		return {
			fractionDigits: value1.fractionDigits || value2.fractionDigits,
			centAmount: (value1.centAmount || 0) + (value2.centAmount || 0),
			currencyCode: value1.currencyCode || value2.currencyCode || "USD",
		};
	}

	static subtractCurrency(value1: Money, value2: Money): Money {
		if (value1.fractionDigits !== value2.fractionDigits) {
			throw new Error(
				`Money with different fraction codes passed to subtractCurrency, value returned will be inaccurate. ` +
					`Value 1: ${value1.fractionDigits}, value 2: ${value2.fractionDigits}`
			);
		}

		if (value1.currencyCode !== value2.currencyCode) {
			throw new Error(
				`Money with different currency codes passed to subtractCurrency, value returned will be inaccurate. ` +
					`Value 1: ${value1.currencyCode}, value 2: ${value2.currencyCode}`
			);
		}

		return {
			fractionDigits: value1.fractionDigits || value2.fractionDigits,
			centAmount: (value1.centAmount || 0) - (value2.centAmount || 0),
			currencyCode: value1.currencyCode || value2.currencyCode || "USD",
		};
	}

	static multiplyCurrency(value: Money, numberOfItems: number): Money {
		return {
			fractionDigits: value.fractionDigits,
			centAmount: (value.centAmount || 0) * numberOfItems,
			currencyCode: value.currencyCode || "USD",
		};
	}
}
