import { HTMLAttributes } from 'react';
import { ProductTileTitle } from './product-tile-title';
import { CardContent } from '@/components/ui/components/card';
import { ProductTilePanel } from './product-tile-panel';
import { cn } from '@/lib/utils';

export const ProductTileBase = ({
	children,
	className,
}: HTMLAttributes<HTMLDivElement> & { className?: string }) => {
	return (
		<CardContent className={cn('asio-product-tile', className)}>
			{children}
		</CardContent>
	);
};

type ProductTileType = typeof ProductTileBase & {
	Panel: typeof ProductTilePanel;
	Title: typeof ProductTileTitle;
};

const ProductTile: ProductTileType = ProductTileBase as ProductTileType;

ProductTile.Title = ProductTileTitle;
ProductTile.Panel = ProductTilePanel;

export { ProductTile };
