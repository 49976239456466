import { HTMLAttributes } from "react";
import { ProductPriceDiscount } from "./price-discount";
import { ProductPriceValue } from "./price-value";
import { PriceWithDiscount } from "./templates/price-with-discount";
import { Money } from "@/lib/currency";
import { cn } from "@/lib/utils";

type ProductPriceBaseProps = {
	variant?: "default" | "discount";
	price?: Money;
	locale?: string;
	discountedPrice?: Money;
	className?: string;
} & HTMLAttributes<HTMLDivElement>;

export const ProductPriceBase = ({
	variant = "default",
	children,
	price,
	locale,
	discountedPrice,
	className,
}: ProductPriceBaseProps) => {
	if (variant === "discount") {
		return (
			price &&
			discountedPrice && (
				<PriceWithDiscount
					price={price}
					discountedPrice={discountedPrice}
					locale={locale}
					className={cn("asio-product-price__container", className)}
				/>
			)
		);
	}

	return (
		<div className={cn("asio-product-price__container", className)}>
			{children}
		</div>
	);
};

type ProductPriceType = typeof ProductPriceBase & {
	Value: typeof ProductPriceValue;
	DiscountPrice: typeof ProductPriceDiscount;
};

const ProductPrice: ProductPriceType = ProductPriceBase as ProductPriceType;

ProductPrice.Value = ProductPriceValue;
ProductPrice.DiscountPrice = ProductPriceDiscount;

export { ProductPrice as Price };
