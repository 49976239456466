import React, { PropsWithChildren, createElement } from "react";
import { cn } from "@/lib/utils";

export const headingSizeList = {
	xs: "text-xs",
	sm: "text-sm",
	base: "text-base",
	lg: "text-lg",
	xl: "text-xl",
	"2xl": "text-2xl",
	"3xl": "text-3xl",
	"4xl": "text-4xl",
	"5xl": "text-5xl",
	"6xl": "text-6xl",
} as any;

export const textSizeList = {
	xs: "text-xs",
	sm: "text-sm",
	base: "text-base",
	lg: "text-lg",
	xl: "text-xl",
};

const weightList = {
	thin: "font-thin",
	normal: "font-normal",
	bold: "font-bold",
};

const alignList = {
	left: "text-left",
	center: "text-center",
	right: "text-right",
	justify: "text-justify",
};

type TextProps = {
	children: React.ReactNode;
	className?: string;
	as?: "p" | "label" | "div" | "span" | "article" | "";
	size?: "xs" | "sm" | "base" | "lg" | "xl";
	weight?: "thin" | "normal" | "bold";
	align?: "left" | "center" | "right" | "justify";
	[key: string]: any; // Additional prop types
};

const Text = ({
	children,
	className,
	as = "p",
	size,
	weight,
	align,
	...restProps
}: TextProps & PropsWithChildren) => {
	const classes = cn(
		className,
		size && textSizeList[size],
		weight && weightList[weight],
		align && alignList[align]
	);

	if (as === "") return <></>;
	return createElement(as, { className: classes, ...restProps }, children);
};

function Typography({ children }: PropsWithChildren) {
	return <div>{children}</div>;
}

type HeadingProps = {
	children: React.ReactNode;
	className?: string;
	as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "header" | "";
	size?:
		| "xs"
		| "sm"
		| "base"
		| "lg"
		| "xl"
		| "2xl"
		| "3xl"
		| "4xl"
		| "5xl"
		| "6xl";
	weight?: "thin" | "normal" | "bold";
	align?: "left" | "center" | "right";
	[key: string]: any; // Additional prop types
};

const Heading: React.FC<HeadingProps> = ({
	children,
	className,
	as = "h1",
	size,
	weight,
	align,
	...restProps
}) => {
	const classes = cn(
		className,
		size && headingSizeList[size],
		weight && weightList[weight],
		align && alignList[align]
	);

	if (as === "") return <></>;
	const Tag = as;

	return (
		<Tag className={classes} {...restProps}>
			{children}
		</Tag>
	);
};

Typography.Text = Text;
Typography.Heading = Heading;

export { Typography };
