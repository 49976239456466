import { usePathname, useRouter } from "next/navigation";
import { useTransition } from "react";

export function useUrlParams() {
	const pathname = usePathname();
	const { replace } = useRouter();
	const [isPending, startTransition] = useTransition();

	const setParams = (paramKey: string, paramValue: string) => {
		const params = new URLSearchParams(window.location.search);

		if (paramValue !== "") {
			params.set(paramKey, paramValue);
		} else {
			params.delete(paramKey);
		}

		startTransition(() => {
			replace(`${pathname}?${params.toString()}`);
		});
	};

	return { setParams, isPending };
}
