import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
	"asio-button inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ",
	{
		variants: {
			variant: {
				default:
					"bg-primary text-primary-foreground hover:bg-primary/90 text-sm font-medium",
				destructive:
					"bg-destructive text-destructive-foreground hover:bg-destructive/90 text-sm font-medium",
				outline:
					"border border-input bg-background hover:bg-accent hover:text-accent-foreground text-sm font-medium",
				primary:
					"bg-primarybtn rounded-sm h-11 md:w-[220px] w-full text-white font-poppins font-semibold text-[14px] leading-[18px]",
				secondary:
					"bg-white text-preheading border-2 border-preheading rounded-sm h-11 md:w-[220px] w-full font-poppins font-semibold text-[14px] leading-[18px]",
				ghost:
					"hover:bg-accent hover:text-accent-foreground text-sm font-medium",
				link: "text-primary underline-offset-4 hover:underline",
				circle: "block rounded-full",
			},
			size: {
				default: "h-10 px-4 py-2",
				sm: "h-9 rounded-md px-3",
				lg: "h-11 rounded-md px-8",
				icon: "h-10 w-10",
				custom: "",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{ className, variant, size, asChild = false, isLoading = false, ...props },
		ref
	) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				aria-label={props.children as string}
				disabled={isLoading}
				{...props}
			/>
		);
	}
);
Button.displayName = "Button";

export { Button, buttonVariants };
