import { Typography } from "@/components/ui/components/typography";
import { cn } from "@/lib/utils";

type ProductTileTitleProps = {
	value: string;
	className?: string;
};

export const ProductTileTitle = ({
	value,
	className,
}: ProductTileTitleProps) => {
	return (
		<Typography.Text className={cn("asio-product-tile__title", className)}>
			{value}
		</Typography.Text>
	);
};
