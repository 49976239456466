import {
	ComposableCommerce,
	ComposableCommerceEvents,
} from "@commercetools/frontend-composable-commerce";
import { SDK } from "@commercetools/frontend-sdk";
import { getLocalizationInfo } from "./project.config";

class CommercetoolsSDK extends SDK<ComposableCommerceEvents> {
	composableCommerce!: ComposableCommerce;
	constructor() {
		super();
		this.composableCommerce = new ComposableCommerce(this);
		this.on("errorCaught", (event) => {
			console.log("SDK error: ", event.data);
		});
	}

	configureForNext(nextJsLocale: string) {
		const { locale, currency } = getLocalizationInfo(nextJsLocale);

		sdk.configure({
			locale,
			currency,
			extensionVersion: process.env.NEXT_PUBLIC_EXT_BUILD_ID ?? "dev",
			endpoint: (process.env.NEXT_PUBLIC_FRONTASTIC_HOST as string).split(
				"/frontastic"
			)[0],
		});
	}
}

const sdk = new CommercetoolsSDK();
export { sdk };
