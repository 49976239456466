import { Money } from "@/lib/currency";
import React from "react";
import { Price } from "@/components/product/detail/price";

export type PriceWithDiscountProps = {
	price: Money;
	locale?: string;
	discountedPrice?: Money;
	className?: string;
};

export const PriceWithDiscount = ({
	price,
	locale,
	discountedPrice,
	className,
}: PriceWithDiscountProps) => (
	<Price className={className}>
		<div className="flex md:gap-4 gap-2">
			<Price.DiscountPrice discountedPrice={discountedPrice ??price} locale={locale} />
			{discountedPrice && <Price.Value price={price} locale={locale} />}
		</div>
	</Price>
);
